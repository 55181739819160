import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ConnectForm from '../components/ConnectForm'
import Button from "../components/basic/button"
import cteduLogo from '../images/LOGO-CTEDU-02.svg'
// import { ScheduleCallSection } from '../components/ScheduleCall'

const SplitSection = ({htmlLeft, htmlRight, widthLeft, widthRight, className}) => {
    return (
        <div className={`branding-container flex flex-row flex-wrap items-center justify-center ${className}`}>
            <div className={`w-full ${widthLeft} flex flex-col lg:pr-10 space-y-10 mb-10 lg:mb-0`}>
                {htmlLeft}
            </div>
            <div className={`w-full ${widthRight} flex flex-row items-center`}>
                <div className='w-0.5 h-72 bg-black mr-20 hidden lg:block md:block'></div>
                <div className='flex flex-col space-y-5'>
                    {htmlRight}
                </div>
            </div>
        </div>
    )
}

// const RoundedRectangle = ({direction, color, styles}) => {
//     let directionStyle = direction === 'left' ? {
//         left: '-25%',
//         borderTopRightRadius: '125px',
//         borderBottomRightRadius: '125px',
//     } : {
//         right: '-25%',
//         borderTopLeftRadius: '125px',
//         borderBottomLeftRadius: '125px',                
//     }
//     return (
//         <div
//             style={{
//                 height: '250px',
//                 width: '100%',
//                 zIndex: '-100',
//                 ...directionStyle,
//                 ...styles
//             }}
//             className={`absolute hidden lg:block md:block ${color}`}
//         />
//     )
// }

export const SectionOne = ({connectFormBgColor, connectFormTextColor, textHighlight, roundedRectangle}) => {
    return (
        <div>
            <div className='branding-container flex flex-row flex-wrap items-center md:justify-center space-y-10 p-0 pb-10 pt-10 lg:pb-20 lg:pt-20 lg:pr-10 lg:space-y-0'>
                <div className='flex w-full lg:w-2/4 md:w-full flex-col justify-center items-center lg:items-start p-10 pb-0 pt-0 relative'>
                    <StaticImage 
                        src='../images/glasses.png'
                        alt='Laurence Hewitt Headshot'
                        className='rounded-full'
                        style={{zIndex: '-10', maxWidth: '500px'}}
                    />
                    <div className='space-y-10'>
                        <div>
                            <div className='shadow-lg -mt-20 z-10 rounded-lg p-5' style={{backgroundColor: 'rgba(255, 255, 255, 0.9)', maxWidth: '500px'}}>
                                <p style={{fontSize: '200px', lineHeight: '0'}} className={textHighlight}>“</p>
                                <p className='branding-h5 font-jetColor pb-2'>Coach Training EDU™ helped me launch my coaching career to another level.</p>
                                <hr></hr>
                                <p className='pt-2'><strong>Laurence Hewitt </strong><br></br>CTEDU Graduate, IBM Executive & Agile Coach</p>
                            </div>
                            {/* <p className='pr-5 pt-5 max-w-lg font-black'>- Laurence Hewitt, <br></br>CTEDU Graduate, IBM Executive & Agile Coach</p> */}
                        </div>

                        <div className='max-w-lg space-y-3'>
                            {/* <p>If you’re passionate about bringing more curiosity, empathy, and personal growth into the world, you’re in the right place.</p> */}
                            <p> The Executive & Team Coaching Program is a coach training program accredited by the ICF designed to equip you with the skills and tools to launch a successful executive coaching practice or to apply coaching concepts to businesses.</p>
                            <p>As an Executive Coach, you’ll help facilitate self-discovery and transform business fields with cutting-edge coaching concepts revolutionizing the way people work.</p>
                            {/* <p className='text-lg'>You’ve struggled to find a career that lets you work from home, help people, and be profitable.</p>
                            <p className='text-lg'>But you also want something that, while being profitable, lets you serve your passions.</p>
                            <p className='text-lg'>Not only that, you want a career that sustains you on a personal level with a community of support.</p> */}
                        </div>
                        {/* <div className='max-w-lg space-y-5'>
                            <h5 className={`branding-h3 lg:branding-h2 text-jetColor font-black`}>It’s your career.</h5>
                            <h5 className={`branding-subheader ${textHighlight}`}>We’ll help you design it.</h5>
                        </div> */}
                    </div>
                </div>
                <ConnectForm bgColor={connectFormBgColor} textColor={connectFormTextColor}/>
            </div>
            {/* {
                roundedRectangle && 
                <RoundedRectangle
                    className='mt-10'
                    direction='left'
                    color='bg-executive-background'
                />
            } */}
        </div>
    )
}

export const ExecutivePageComponent = ({styles}) => {
    
    return (<>
            <SectionOne connectFormBgColor='bg-executive-primary' connectFormTextColor='text-white' roundedRectangle={true} textHighlight='text-executive-primary'/>

            <div className='w-full bg-executive-background'>
                <div className='branding-container items-center p-10 pt-20 pb-20 space-y-12'>
                    <div className='space-y-5 text-center'>
                        <h5 className='branding-h5 text-jetColor w-full'>There has been a sharp rise among managers/leaders using coaching skills in the strength of agreement that clients expect their coaches to be certified/credentialed. </h5>
                        <h6 className='branding-h6 text-jetColor'>- 2020 ICF Global Coaching Study: Executive Summary</h6>
                        {/* <h6 className='branding-h5 text-executive-secondary'>Some of the organizations that have worked with CTEDU: </h6> */}
                    </div>
                </div>
            </div>
            <SplitSection
                className='p-10 lg:pb-20 lg:pt-20'
                widthLeft='w-full lg:w-3/5'
                widthRight='w-full lg:w-2/5'
                htmlLeft={<>
                    <div className='space-y-5'><h3 className='branding-h3 lg:branding-h2 text-jetColor font-black'>Coach Certified at the Highest Levels</h3>
                    <h2 className='branding-subheader text-executive-secondary'>Leverage the New Guidelines for Accredited Team Coaching</h2></div>
                    <div className='space-y-5'>
                        <p className='text-xl max-w-2xl'>The International Coach Federation (ICF) is the current gold standard of coaching. The Executive & Team Coaching Program is recognized by the ICF as an Accredited Coach Training Program (ACTP), the highest possible level. By training with Saint Joseph's University and Coaching Training EDU™ (CTEDU), you put yourself on the most robust and quickest track to earn your coaching credential.</p>
                        <p className='text-xl max-w-2xl'>In early 2021 the ICF established new guidelines to become an Accredited Team Coach. The <strong>Executive & Team Coaching Program </strong>addresses the ICF’s core coaching criteria as well as includes materials, skill building, and exercises that meet the new team coach competencies.</p>
                    </div>
                </>}
                htmlRight={<>
                    <StaticImage src='../images/ACTP.png' alt='ACTP, ICF Accredited Coach Training Program'/>
                </>
            }/>
            <div className='w-full bg-executive-secondary divide-y divide-dotted divide-white'>
                <h3 className='branding-h3 text-white text-center p-10 pt-20'>The Coaching Profession by the Numbers</h3>
                <div className='branding-container text-white items-center lg:items-start text-center lg:text-left flex flex-col lg:flex-row content-start lg:justify-between p-10 pb-20 space-y-10 lg:space-y-0'>
                    <div className='flex flex-col space-y-5' style={{maxWidth: '250px'}}>
                        <h4 className='branding-h3 lg:branding-h2'>2nd</h4>
                        <p className='branding-h6'>The coaching industry is the second fasted growing sector in the world</p>
                        <p style={{fontStyle: 'italic'}}>*Statistic from PwC.com</p>
                    </div>
                    <div className='flex flex-col space-y-5' style={{maxWidth: '250px'}}>
                        <h4 className='branding-h3 lg:branding-h2'>22%</h4>
                        <p className='branding-h6'>Increase in demand for coaching in the next six years.</p>
                        <p style={{fontStyle: 'italic'}}>*Statistic from Department of Labor</p>
                    </div>
                    <div className='flex flex-col space-y-5' style={{maxWidth: '250px'}}>
                        <h4 className='branding-h3 lg:branding-h2'>$100,668</h4>
                        <h4 className='branding-h6'>Executive coaches earn a median income of $100,668 in the United States.</h4>
                        <p style={{fontStyle: 'italic'}}>*Statistic from Salary.com</p>
                    </div>
                    <div className='flex flex-col space-y-5' style={{maxWidth: '250px'}}>
                        <h4 className='branding-h3 lg:branding-h2'>$2.849 Billion</h4>
                        <p className='branding-h6'>2019 Estimated Global Revue from Coaching</p>
                        <p style={{fontStyle: 'italic'}}>*Statistic from International Coaching Federation (ICF)</p>
                    </div>
                </div>
            </div>
            <div className='branding-container flex flex-wrap-reverse flex-row justify-center lg:justify-between p-10 lg:pb-20 lg:pt-20' >
                <div className='w-full lg:w-1/2 flex flex-col justify-center'>
                    <h2 className='branding-h3 lg:branding-h2 text-jetColor mb-10'>Tools & Support to Launch Your Executive Coach Career</h2>
                    <div className='space-y-5'>
                        <div>
                            <h3 className='branding-subheader text-jetColor'>Executive Coach Toolkit</h3>
                            <p>From professional leadership assessments to effective feedback tools, you’ll gain a practical toolbox of research-based exercises to use with clients. </p>
                        </div>
                        <div>
                            <h3 className='branding-subheader  text-jetColor'>A Business Success Roadmap</h3>
                            <p>Included in each program is a business success roadmap and business materials from sample contracts to guidelines on pricing and getting your first five clients or becoming an in-house executive coach.</p>
                        </div>
                        <div>
                            <h3 className='branding-subheader  text-jetColor'>Passionate Community of Coaches</h3>
                            <p>The friendships formed in each training program lasts a lifetime. You will make lifelong friends in your Coach Training EDU™ and Saint Joseph’s University training as well as be connected to a fantastic group.</p>
                        </div>
                    </div> 
                </div>
                <div className='w-full lg:w-1/2 flex justify-center mb-10 lg:mb-0'>
                    <StaticImage src='../images/Red_Lady.png' width={550} alt='Professional female executive coach' />
                </div>             
           </div>
            <div className='bg-executive-primary w-full'>
                <div className='branding-container text-white flex flex-wrap flex-row items-center justify-center lg:justify-between space-y-10 lg:space-y-0 p-10 lg:pb-20 lg:pt-20 '>
                    <div className='w-full lg:w-1/2 flex justify-center'>
                        <StaticImage src='../images/blue-lady-wheelchair.png' width={650} alt='Executive and team coaching professionals' className='m-0 lg:mb-10'/>
                    </div>   
                    <div className='w-full lg:w-1/2'>
                        <h2 className='branding-h3 lg:branding-h2 mb-10'>The 3 Elements of the Executive & Team Coaching Program</h2>
                        <div className='space-y-5'>
                            <div>
                                <h3 className='branding-subheader'>1.0 Executive Coach Training</h3>
                                <p>This program begins with our foundational executive coaching course which combines ICF-accredited training with tools and exercises designed for executives and entrepreneurs.</p>
                            </div>
                            <div>
                                <h3 className='branding-subheader'>2.0 Advanced Coaching</h3>
                                <p>The next phase takes a deeper dive into the research and theory supporting coaching and the key elements to establishing a successful executive coaching practice while learning advanced coaching tools.</p>
                            </div>
                            <div>
                                <h3 className='branding-subheader'>3.0 Team Coaching</h3>
                                <p>Throughout this stage, you will learn team-based coaching techniques, develop strategies to balance the demands of leaders and their teammates, and help build more effective team dynamics for your clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='bg-jetColor text-white'>
                <div className='branding-container p-10 lg:pb-20 lg:pt-20 space-y-5 text-center'>
                    <h3 className='branding-h3'>Experience Coach Training Refined by Over a Decade of Experience</h3>
                    <p>Since 2009 CTEDU has trained thousands of coaches from New York to Dubai in over fifty countries.</p>
                </div>
            </div> */}
            <div className='bg-executive-secondary w-full'>
                <div className='branding-container flex flex-wrap-reverse flex-row justify-center lg:justify-between items-center p-10 lg:pb-20 lg:pt-20 lg:space-y-0'>
                    <div className='w-full lg:w-1/2 divide-y divide-solid divide-executive-secondary text-white'>
                        <p className='branding-subheader pb-5 text-white'>“I finally have the tools to use in my professional life to serve my clients and continue to learn myself. I enjoyed the class size and was able to learn from my peers as well.”</p>
                        <p className='text-lg pt-5'><strong>Donna Oard</strong>
                        <br></br>CTEDU Graduate, Independent Executive Coach</p>
                    </div>
                    <div className='w-full lg:w-1/2 flex justify-center mb-10 lg:mb-0'>
                        <StaticImage 
                            src='../images/DonnaOard.png'
                            alt='Donna Oard, Coach Training EDU Graduate'
                            className='rounded-full'
                            width={500}
                            height={500}
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-center'>
                <SplitSection
                    className='p-10 lg:pb-20 lg:pt-20'
                    widthLeft='w-full lg:w-2/5'
                    widthRight='w-full lg:w-3/5'
                    htmlLeft={<>
                        <p className='branding-subheader'>
                            You can enroll in a coach training program that provides everything you need to kickstart your executive coaching career with transparency, empathy, and results.
                        </p>
                    </>}
                    htmlRight={<>
                        {/* <h2 className='branding-h1'>What is included</h2> */}
                        <ul className='list-disc space-y-5'>
                            <li>ICF accreditation</li>
                            <li>An amazing classroom experience that is live, remote, and entirely hands-on</li>
                            <li>Research-backed textbooks</li>
                            <li>Real-world experiences</li>
                            <li>A coaching toolkit full of curated exercises and materials you can use throughout your entire coaching career</li>
                            <li>A community of support from graduates, current students, and so much more!</li>
                        </ul>
                    </>
                }/>
            </div>
            <div className='bg-executive-background'>
                <div className='branding-container grid grid-cols-1 lg:grid-cols-2 p-10 lg:pb-20 lg:pt-20 gap-10'>
                    <div className='flex flex-row items-center justify-center'>
                        <img src={cteduLogo} style={{ width: '300px'}} alt='ctedu logo' />
                    </div>
                    <div className='flex flex-col justify-center'>
                        <h2 className='branding-h3 lg:branding-h2 mb-10'>About Coach Training EDU™</h2>
                        <p className='max-w-3xl'><strong className='underline'>Coach Training EDU™ </strong>offers comprehensive coach training to people who want to improve lives, strengthen relationships, and empower executives. As a global ICF-accredited coaching school, CTEDU offers an immersive curriculum and lifelong support for graduates establishing their coaching careers. </p>
                    </div>
                </div>
            </div>

            <div className='bg-executive-secondary w-full'>
                <div className='branding-container flex flex-wrap-reverse flex-row justify-center lg:justify-between items-center p-10 lg:pb-20 lg:pt-20 lg:space-y-0'>
                    <div className='w-full lg:w-1/2 divide-y divide-solid divide-executive-secondary text-white'>
                        <h2 className='branding-h2 mb-10'>A Message from St. Joseph’s University</h2>
                        <p className='branding-subheader pb-5 text-white'>"We chose CTEDU as our partner for the Executive and Team Coaching Program because they are ACTP accredited by the ICF and have excellent graduate reviews. They blend the ICF's core competencies beautifully through their comprehensive program while exposing the participants to various coaching approaches, situations, team coaching and a very valuable and rare business component. In addition to this, their values align with ours regarding ethics, inclusivity, equality and quality academics."</p>
                        <p className='text-lg pt-5'><strong>Marie Stevenson</strong>
                        <br></br>Director of Professional Development Saint Joseph's University</p>
                    </div>
                    <div className='w-full lg:w-1/2 flex justify-center mb-10 lg:mb-0'>
                        <StaticImage 
                            src='../images/Marie-Stevenson.png'
                            alt='Donna Oard, Coach Training EDU Graduate'
                            className='rounded-full'
                            width={500}
                            height={500}
                        />
                    </div>
                </div>
            </div>

            <div className='branding-container flex flex-wrap flex-row items-center lg:justify-between p-10 pb-14 lg:pb-20 lg:pt-20 space-y-12 lg:space-y-0'>
                <div className='w-full lg:w-1/2'>
                    <h3 className='branding-h3 lg:branding-h2 text-jetColor mb-10'>Let’s Continue the Conversation</h3>
                    <div className='space-y-5 mb-10'>
                        <p>If you’re seeking change and growth in both your professional and personal life, and have the desire to assist others in reaching their own developmental goals, you’re in the right place.  We’d love to continue the conversation.</p>
                        {/* <p>Whether you’re new to coaching or already have some coach training, Coach Training EDU’s programs will both challenge and equip you to build the business and career you’re yearning to create. </p>
                        <p>Participants all over the world rave about the transformative experience both in diving deep into weekly readings and recordings as well as forging friendships with fellow coaches.</p>
                        <p>If you’re passionate about bringing more curiosity, empathy, and personal growth into the world, you’re in the right place. We’d love to continue the conversation.</p> */}
                    </div>
                    <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5'>
                        <Button to='/#contact' type='sju3' size='lg'>Connect with our team</Button>
                        <Button to='/executivecoaching-page2/' type='sju3' size='lg'>More Info Page 2</Button>
                    </div>

                </div>                
                <div className='w-full lg:w-2/5 flex justify-center'>
                    <StaticImage src='../images/EXECUTIVE-illustration3.png' width={450} alt='Professional leadership coaches'/>
                </div>
            </div>
        </>
    )
}