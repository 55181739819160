import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { ExecutivePageComponent } from '../components/ExecutivePageComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import Head from '../components/Head'
import Button from "../components/basic/button"
import Layout from '../components/Layout'

const Home = () => {
  return (
    <Layout>
      <div className='w-full'>
        <Head title="Saint Joseph's University Executive and Team Coaching Program" description="The Executive and Team Coaching Certification Program is designed to equip leaders with the skills to launch a successful executive coaching practice."/>

          <div className='relative' style={{height: '675px'}}>
              <StaticImage
                layout='fullWidth'
                src='../images/sju-hero.png'
                className='max-h-full'
                // objectFit='cover'
                style={{height: '675px'}}
                imgStyle={{height: '675px'}}
                alt="Saint Joseph's University Campus"
              />
            <div style={{ height: '675px' }} className='absolute top-0 w-full bg-executive-primary bg-opacity-80'/>
            <div style={{ height: '675px' }} className='absolute top-0 flex items-center w-full'>
              <div className='branding-container w-full pr-10 pl-10 pt-0 pb-0 lg:p-10'>
                  <h1 className='branding-h2 lg:branding-h1 lg:branding-h1 text-white font-bold mb-5 md:mb-10 lg:mb-10'>Become an Executive & Team Coach</h1>
                  <h2 className='branding-h5 text-white max-w-4xl mb-10 md:mb-10 lg:mb-12'>The <strong>Executive & Team Coaching Program</strong> is an International Coaching Federation (ICF) accredited coach training program designed to equip you with the skills and tools to launch a successful executive coaching practice.</h2>
                  <Button to='/#contact' type='sju' size='lg' icon={<FontAwesomeIcon icon={faPhone} flip='horizontal' className='mr-3'/>}>Connect with our team</Button>
              </div>
            </div>
          </div>
          <ExecutivePageComponent colorScheme='executive'/>
      </div>
    </Layout>
  )
}

export default Home;