import React from "react"

import { navigate } from "gatsby"
import { useFormik } from "formik"
import axios from "axios"
import Button from "../components/basic/button"

//you need to provide bgColor as a full class. ie: bg-executive-background
const ConnectForm = ({bgColor, textColor}) => {

    const validate = (values) => {
        const errors = {};
        const required = ['firstName', 'lastName', 'email', 'phone']

        required.map((value) => {
            if(!values[value]) errors[value] = '*This field is required.';
            return true;
        });

        if (!values.email) {
            errors.email = '*This field is required.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = '*Invalid email address.';
        }
    
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        },
        validate,
        onSubmit: values => {
        //   btnRef.current.setAttribute("disabled", "disabled");
            const url =
            "https://us-central1-ctedu-home-static.cloudfunctions.net/forms-forms"
            let data = values
            data["form"] = "contact"
            data["program"] = "Executive SJU"
            axios.post(url, data).then(() => navigate('/thank-you'));
            // alert(JSON.stringify(values, null, 2));
        },
    })

    return (
        <div 
            id='contact'
            style={{ minHeight: '850px'}}
            className={`w-full lg:w-2/4 flex flex-col items-center justify-around rounded-lg p-10
            ${bgColor} ${textColor}`}
            >
            <div className='text-left space-y-5'>
                <h2 className='branding-h3 lg:branding-h2'>Executive & Team Coach Training</h2>

                {/* <h3 className='branding-h2'>The Strongest Foundation for Your Career.</h3> */}
                {/* <h3 className='text-lg'>#DoYourLifesWork</h3> */}
                <h3 className='branding-subheader font-extralight'>Let’s talk about your success.</h3>
            </div>

            <div className='w-full'>
                <form onSubmit={formik.handleSubmit} className='flex flex-col items-center space-y-12' id='connect'>
                    <div className='flex flex-col w-full space-y-10 text-black'>
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-row'>
                                <label className='text-white mb-2' htmlFor='firstName'>First Name</label>
                                {formik.errors.firstName? <div className="text-executive-errors pl-2">{formik.errors.firstName}</div> : null }
                            </div>
                            <input
                                placeholder="First Name"
                                className='focus:bg-white rounded h-14 pl-5'
                                onChange={formik.handleChange}
                                id='firstName'
                                value={formik.values.firstName}
                            />
                        </div>
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-row'>
                                <label className='text-white mb-2' htmlFor='lastName'>Last Name</label>
                                {formik.errors.lastName? <div className="text-executive-errors pl-2">{formik.errors.lastName}</div> : null}
                            </div>
                            <input
                                placeholder="Last Name"
                                className='focus:bg-white rounded h-14 pl-5'
                                onChange={formik.handleChange}
                                id='lastName'
                                value={formik.values.lastName}
                            />
                        </div>
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-row'>
                                <label className='text-white mb-2' htmlFor='email'>Email</label>
                                {formik.errors.email? <div className="text-executive-errors pl-2">{formik.errors.email}</div> : null}
                            </div>
                            <input
                                placeholder="Email"
                                className='focus:bg-white rounded h-14 pl-5'
                                onChange={formik.handleChange}
                                id='email'
                                value={formik.values.email}
                            />
                        </div>
                        <div className='flex flex-col w-full'>
                            <div className='flex flex-row'>
                                <label className='text-white mb-2' htmlFor='phone'>Phone Number</label>
                                {formik.errors.phone? <div className="text-executive-errors pl-2">{formik.errors.phone}</div> : null}
                            </div>
                            <input
                                placeholder="Phone Number"
                                className='focus:bg-white rounded h-14 pl-5'
                                onChange={formik.handleChange}
                                id='phone'
                                value={formik.values.phone}
                            />
                        </div>
                    </div>
                    <Button size="lg" type="sju" styles={{marginTop: '30px'}}>
                        Connect With Our Team
                    </Button>
                </form>
            </div>
        </div>
    )
}

export default ConnectForm;